import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import React from "react";

import background from "../../assets/fondoPagina1.png";
import poppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../assets/fonts/Poppins-Medium.ttf";
import poppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import insta from "../../assets/insta.png";
import logo from "../../assets/Logo_CTC.png";
import mail from "../../assets/mail.png";
import red from "../../assets/red.png";
import tel from "../../assets/tel.png";
import { stringToCurrency } from "../../helpers/currencies";
import getOffer from "../../helpers/offerCalculation";

// Create styles
Font.register({
  family: "poppins",
  fonts: [
    { src: poppinsRegular },
    { src: poppinsMedium, fontWeight: "medium" },
    { src: poppinsBold, fontWeight: "bold" }
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "poppins",
    fontSize: 14
  },
  header: {
    marginVertical: "30px",
    flexDirection: "row",
    height: "70px"
  },
  headerLeft: {
    width: "40%",
    marginLeft: 30
  },
  headerRight: {
    width: "60%",
    backgroundColor: "#EE7833",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    padding: "15px"
  },
  title1: {
    fontSize: "20px",
    color: "#fff",
    fontWeight: "bold"
  },
  title2: {
    fontSize: "16px"
  },
  container: { paddingHorizontal: "30px" },
  homeInfo: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  homeInfoText: {
    fontSize: "15",
    color: "#718195"
  },
  blueSquare: {
    marginHorizontal: "auto",
    backgroundColor: "#0053C3",
    border: "1px solid black",
    position: "relative",
    zIndex: "5",
    width: "302px",
    height: "262px",
    borderRadius: "30px"
  },
  orangeSquare: {
    backgroundColor: "#EE7833",
    position: "absolute",
    top: "30px",
    zIndex: "5",
    width: "300px",
    height: "200px",
    borderRadius: "30px"
  },
  whiteSquare: {
    backgroundColor: "#fff",
    position: "absolute",
    top: "60px",
    zIndex: "5",
    width: "300px",
    height: "200px",
    borderRadius: "30px",
    padding: "20px"
  },
  whiteSquareContainer: {
    display: "flex",
    flexDirection: "row"
  },
  image: {
    width: "60%",
    marginLeft: 2
  },
  logoDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  icon: {
    height: "15px",
    width: "15px"
  }
});

// Create Document Component
const Pdf = ({ property, debtsRef = [], source = null }) => (
  <Document>
    <Page size="letter" style={styles.page}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Image style={styles.image} src={logo} />
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.title1}>¡Felicidades!</Text>
          <Text style={styles.title2}>Estás muy cerca de vender tu casa.</Text>
        </View>
      </View>
      <View style={[styles.container, { position: "relative" }]}>
        <Image
          src={background}
          style={{ width: 550, height: 550, position: "absolute" }}
        />
        <View style={styles.homeInfo}>
          <Text>
            La oferta por tu casa ubicada en:
            <Text style={styles.homeInfoText}>
              {" "}
              {property?.address?.street ?? ""}
              {property?.address?.street && " "}
              {property?.address?.numInt ?? ""}
              {property?.address?.numInt && ", "}
              {property?.address?.numExt ?? ""}
              {property?.address?.numExt && ", "}
              {property?.settlement ?? ""}
              {property?.settlement && ", "}
              {property?.city ?? ""}
              {property?.city && ", "}
              {property?.state ?? ""}
              {property?.state && ", "}
              {property?.postalCode && "C.P.: "}
              {property?.postalCode ?? ""}
            </Text>
          </Text>
          <Text>
            Que cuenta con las siguientes características:
            <Text style={styles.homeInfoText}></Text>
          </Text>
          <Text>
            Que actualmente cuenta con una hipoteca con:
            <Text style={styles.homeInfoText}>
              {" "}
              {property?.debtCredit &&
                stringToCurrency(property?.debtCredit)}{" "}
            </Text>
          </Text>
          <Text>
            Con el número de crédito:
            <Text style={styles.homeInfoText}>
              {" "}
              {property?.sellerRef?.credit}{" "}
            </Text>
          </Text>
          <Text>Es la siguiente:</Text>
        </View>
        <View>
          <View style={styles.blueSquare}>
            <Text
              style={{ color: "#fff", textAlign: "center", marginTop: "5px" }}
            >
              {property?.sellerRef?.name ?? ""}
              {property?.sellerRef?.name && " "}
              {property?.sellerRef?.fLastName ?? ""}
              {property?.sellerRef?.fLastName && " "}
              {property?.sellerRef?.mLastName ?? ""}
            </Text>
            <View style={styles.orangeSquare}>
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "5px" }}
              >
                Oferta:{" "}
                {source === "dashboard-quoter"
                  ? stringToCurrency(Math.round(property?.offer))
                  : stringToCurrency(
                      getOffer(
                        property?.offer ?? property?.quote,
                        property?.interval
                      )
                    )}
              </Text>
            </View>
            <View style={styles.whiteSquare}>
              <View style={styles.whiteSquareContainer}>
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      textAlign: "right",
                      fontSize: "12px"
                    }}
                  >
                    Crédito Hipotecario:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "3px" }}>
                    Mantenimiento:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "3px" }}>
                    Agua:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "3px" }}>
                    Luz:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "3px" }}>
                    Predial:
                  </Text>
                  <Text style={{ textAlign: "right" }}>Impuestos:</Text>
                  <Text
                    style={{
                      textAlign: "right",
                      marginTop: "3px",
                      fontSize: "12px"
                    }}
                  >
                    Adelanto + Comisión:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "3px" }}>
                    Total:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "20px" }}>
                    Saldo:
                  </Text>
                </View>
                <View style={{ width: "50%", paddingLeft: "10px" }}>
                  <Text style={{ textAlign: "left" }}>
                    {stringToCurrency(property?.debtCredit ?? 0)}{" "}
                  </Text>
                  {debtsRef && (
                    <>
                      <Text style={{ textAlign: "left" }}>
                        {stringToCurrency(
                          debtsRef?.find(
                            (debt) => debt.type === "mantenimiento"
                          )?.amount ?? 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "agua")
                            ?.amount ?? 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "luz")
                            ?.amount ?? 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "predial")
                            ?.amount ?? 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "impuestos")
                            ?.amount ?? 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "4px" }}>
                        {stringToCurrency(property.advancedPayment ?? 0)}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "4px" }}>
                        {debtsRef?.length > 0
                          ? stringToCurrency(
                              debtsRef?.reduce(
                                (subtotal, debt) =>
                                  subtotal + Number(debt?.amount),
                                0
                              ) +
                                (Number(property?.debtCredit) ?? 0) +
                                (Number(property.advancedPayment) ?? 0)
                            )
                          : stringToCurrency(Number(property?.debtCredit) ?? 0)}
                      </Text>
                    </>
                  )}
                  <Text style={{ textAlign: "left", marginTop: "20px" }}>
                    {stringToCurrency(Math.round(property?.netOffer))}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text
            style={{
              color: "#0053C3",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "15px"
            }}
          >
            Acepto carta oferta
          </Text>
        </View>
        <View
          style={{
            marginTop: "30px",
            marginHorizontal: "auto",
            width: "300px",
            border: "1px solid black"
          }}
        ></View>
        <View>
          <Text
            style={{
              textAlign: "center",
              marginTop: "5px",
              fontSize: "15px"
            }}
          >
            {property?.sellerRef?.name ?? ""}
            {property?.sellerRef?.name && " "}
            {property?.sellerRef?.fLastName ?? ""}
            {property?.sellerRef?.fLastName && " "}
            {property?.sellerRef?.mLastName ?? ""}
          </Text>
          <Text
            style={{
              color: "#0053C3",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            CLIENTE
          </Text>
        </View>
        <View>
          <Text
            style={{ fontWeight: "bold", color: "#EE7833", fontSize: "12px" }}
          >
            En COMPRAMOSTUCASA.MX
            <Text style={{ color: "#000", fontWeight: "normal" }}>
              {" "}
              ¡Tú decides qué tan rápido quieres vender tu casa!
            </Text>
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "11px",
            color: "#0053C3"
          }}
        >
          <View style={{ fontSize: 10, width: "60%" }}>
            <View style={styles.logoDiv}>
              <Image src={tel} style={styles.icon} />
              <Text> (55) 2462 0819</Text>
            </View>
            <View style={styles.logoDiv}>
              <Image src={red} style={styles.icon} />
              <Text> compramostucasa.mx</Text>
            </View>
            <View style={styles.logoDiv}>
              <Image src={mail} style={styles.icon} />
              <Text> {property?.adviserRef?.email}</Text>
            </View>
            <View style={styles.logoDiv}>
              <Image src={insta} style={styles.icon} />
              <Text> compramostucasa.mx</Text>
            </View>
          </View>
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: 10, textAlign: "right" }}>
              Alexander Von Humboldt 43A, Lomas Verdes 3ra Sección, Naucalpan
              Edo. de Méx. CP 53125
            </Text>
          </View>
        </View>
        <View style={{ width: "100%", marginTop: "8px" }}>
          <Text style={{ fontSize: 8, color: "#EE7833" }}>
            *Aplican términos y condiciones
            <Text style={{ color: "#000" }}>
              {" "}
              | La siguiente oferta tiene una vigencia de 15 días a partir de la
              fecha emitida, misma que puede estar sujeta a cambios a la fecha
              de compraventa. La presente oferta se envía bajo la condición de
              que su contenido es confidencial y no será revelado a ningún
              tercero sin previo aviso. Para mayor información consulta
              directamente con tu asesor.
            </Text>
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default Pdf;
