import CotizerContainer from "./CotizerContainer";

const CotizerPage = () => {
  return (
    <section className="flex-center">
      <CotizerContainer />
    </section>
  );
};

export default CotizerPage;
