import { Grid } from "@mui/material";
import { useContext, useState } from "react";

import { updateProperties } from "../../../api/properties/updateProperties";
import { updateSellers } from "../../../api/seller/updateSellers";
import TokenContext from "../../../context/Token";

const ModalCredit = (props) => {
  const { token } = useContext(TokenContext);
  const [response, setResponse] = useState({});

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [credit, setCredit] = useState({
    creditType: props.creditType ?? "", //infonavit, fovissste, bancario, otro
    debtCredit: props.debtCredit ?? "", //deuda pendiente
    credit: props.credit ?? "", //numero de crédito
    hasBacklogCredit: Boolean(props.hasBacklogCredit) ?? "", //si tiene o no atrasos
    monthBacklogs: props.monthBacklogs ?? 0 //meses de atraso
  });

  const handleChange = async (e) => {
    if (e.target.name === "hasBacklogCredit") {
      setCredit({
        ...credit,
        [e.target.name]: e.target.value === "true" ? true : false
      });
      return;
    }

    setCredit({
      ...credit,
      [e.target.name]: e.target.value
    });
  };

  const sendData = async () => {
    try {
      const response = await updateProperties(token, props.id, credit);

      if (response._id && credit.credit) {
        await updateSellers(token, props.sellerId, { credit: credit.credit });

        setSuccessMessage("Crédito Actualizado Correctamente");
        props.refetch();
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      id={"modalCredit" + props.id}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-gray fw-bold">Crédito</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label className="text-start w-100">Tipo*</label>
                <select
                  required
                  className="form-select"
                  name="creditType"
                  value={credit.creditType ?? ""}
                  onChange={handleChange}
                >
                  <option hidden value="">
                    Tipo de credito
                  </option>
                  <option value="Bancario">Bancario</option>
                  <option value="Fovissste">Fovissste</option>
                  <option value="Infonavit">Infonavit</option>
                  <option value="Otro">Otro</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className="text-start w-100">Num. de Crédito*</label>
                <input
                  required
                  className="form-control"
                  type="text"
                  name="credit"
                  value={credit.credit ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className="text-start w-100">Adeudo Pendiente*</label>
                <input
                  required
                  type={"number"}
                  name="debtCredit"
                  id="debtCredit"
                  value={credit.debtCredit ?? ""}
                  onChange={handleChange}
                  className="w-100 form-control"
                  style={{ resize: "none" }}
                  placeholder="Sólo números, Ej.: 12500"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className="text-start w-100">Pagos Atrasasdos</label>
                <select
                  className="form-select"
                  name="hasBacklogCredit"
                  value={credit.hasBacklogCredit ?? ""}
                  onChange={handleChange}
                >
                  <option hidden value="">
                    seleccionar
                  </option>
                  <option value={true}>Si</option>
                  <option value={false}>No</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className="text-start w-100">Meses de Atraso</label>
                <select
                  className="form-select"
                  name="monthBacklogs"
                  value={credit.monthBacklogs ?? ""}
                  onChange={handleChange}
                >
                  <option hidden value="">
                    seleccionar
                  </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>Más de 1 año</option>
                </select>
              </Grid>
            </Grid>
          </div>
          <div className="modal-footer">
            <div className="d-flex">
              {successMessage && (
                <p className="bg-success text-white py-1 px-1 my-0 rounded mx-1 fw-bold text-center">
                  {successMessage}
                </p>
              )}
              {errorMessage && (
                <p className="bg-danger text-white py-1 px-1 my-0 rounded mx-1 fw-bold text-center">
                  {errorMessage}
                </p>
              )}
              <button
                type="button"
                className="btn-fill-orange mb-1 me-3"
                onClick={sendData}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCredit;
