import { Grid, Typography } from "@mui/material";

import { stringToCurrency } from "../../../helpers/currencies";

const GeneralInfo = ({ property }) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={2} mb={2}>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          component={"span"}
          color="secondary"
          fontWeight={"bold"}
          textAlign="start"
        >
          Ubicacion*
        </Typography>
        <Typography
          variant="h6"
          color={property?.address?.street ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
        >
          {property?.address?.street ?? ""}
          {property?.address?.street && " "}
          {property?.address?.numInt ?? ""}
          {property?.address?.numInt && ", "}
          {property?.address?.numExt ?? ""}
          {property?.address?.numExt && ", "}
          {property?.address?.mzn ? `Mz.: ${property?.address?.mzn}` : ""}
          {property?.address?.mzn && ", "}
          {property?.address?.lt ? `Lt.: ${property?.address?.lt}` : ""}
          {property?.address?.lt && ", "}
          {property?.settlement ?? ""}
          {property?.settlement && ", "}
          {property?.city ?? ""}
          {property?.city && ", "}
          {property?.state ?? ""}
          {property?.state && ", "}
          {property?.postalCode && "C.P.: "}
          {property?.postalCode ?? ""}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.type ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Tipo*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.type ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          M² Terreno
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.mLand ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.address?.mBuilding ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          M² Construcción*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.mBuilding ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.adquisitionYear ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Fecha de adquisición*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.adquisitionYear ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.reasonSale ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Razón de venta*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.reasonSale ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.isInhabited ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Propiedad Inhabitada*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.isInhabited === true ? "si" : "no"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.wantedForSale ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Cantidad deseada*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.wantedForSale
            ? stringToCurrency(property?.wantedForSale)
            : "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color={property?.address?.parkingLot ? "secondary" : "error"}
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Estacionamientos*
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.parkingLot ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        {property.type === "Casa" && (
          <>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
            >
              Pisos/Niveles
            </Typography>
            <Typography variant="h6" color="secondary" textAlign={"start"}>
              {property?.address?.levels ?? "N/A"}
            </Typography>
          </>
        )}
        {property.type === "Departamento" && (
          <>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
            >
              Piso
            </Typography>
            <Typography variant="h6" color="secondary" textAlign={"start"}>
              {property?.address?.floor ?? "N/A"}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Cuartos
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.bedrooms ?? "N/A"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Baños Completos
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.washroom ?? "N/A"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GeneralInfo;
