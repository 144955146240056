import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const createPropertyAppointment = async (token, propertyId, data) => {
  let url = `${REACT_APP_BASE_URL}/properties/${propertyId}/appointment`;

  try {
    const response = await axios.post(
      url,
      { appointment: data },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
