import { Box, Slider, Typography } from "@mui/material";

const QuoterSlider = ({
  onChangeRange,
  rangeDays,
  offer_accepted = false,
  newOffer
}) => {
  // console.log(rangeDays)
  return (
    <Box width={"90%"} marginX="auto">
      <Typography
        variant="body1"
        color="secondary"
        textAlign={"start"}
        fontSize={12}
      >
        selecciona el intervalo
      </Typography>
      <Slider
        disabled={offer_accepted === true && !newOffer}
        aria-label="Intervalo"
        valueLabelDisplay="auto"
        min={0}
        max={365}
        step={30}
        marks
        color="primary"
        value={rangeDays ?? 365}
        onChange={onChangeRange}
      />
    </Box>
  );
};

export default QuoterSlider;
