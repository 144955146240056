import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";

import Loading from "../../components/loading/Loading";
import { stringToCurrency } from "../../helpers/currencies";

const PostalCodeDisplay = ({
  postalCode,
  setPostalCode,
  mBuilding,
  setMBuilding,
  response,
  searchPostalCodePrice,
  clearFields,
  loading,
  settlements,
  setSelectedSettlement,
  selectedSettlement
}) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          component={"form"}
          onSubmit={searchPostalCodePrice}
        >
          <TextField
            required
            sx={{ width: 185, mr: 1 }}
            size="small"
            id=""
            label="C.P."
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
          <TextField
            required
            sx={{ width: 180 }}
            size="small"
            id=""
            label="M² Construccion"
            value={mBuilding}
            onChange={(e) => setMBuilding(e.target.value)}
          />
          <IconButton sx={{ mt: 1 }} type={"submit"} disabled={loading}>
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
          <TextField
            fullWidth
            size="small"
            select
            id="settlements"
            name="settlements"
            label="Colonia"
            value={selectedSettlement}
            onChange={(e) => setSelectedSettlement(e.target.value)}
          >
            {settlements.map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          justifyContent={"center"}
        ></Grid>
        <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
          <Button variant="contained" color="primary" onClick={clearFields}>
            Limpiar Campos
          </Button>
        </Grid>
      </Grid>
      {loading && <Loading />}
      {response && !loading && (
        <Grid container mb={2} columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              ml={2}
            >
              <Typography
                variant="h6"
                component={"span"}
                color="secondary"
                fontWeight={"bold"}
              >
                precio promedio M²:{" "}
              </Typography>
              {stringToCurrency(
                mBuilding < 40
                  ? Math.ceil(response.averageValue * 1.15)
                  : Math.ceil(response.averageValue)
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              ml={2}
            >
              <Typography
                variant="h6"
                component={"span"}
                color="secondary"
                fontWeight={"bold"}
              >
                Precio sugerido:{" "}
              </Typography>
              {mBuilding < 40
                ? stringToCurrency(
                    Math.ceil(response.averageValue * mBuilding * 1.15)
                  )
                : stringToCurrency(
                    Math.ceil(response.averageValue * mBuilding)
                  )}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PostalCodeDisplay;
