import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";

import EditDataClient from "../../components/Modals/EditDataClient";

const InfoClient = ({ sellerRef, refetch }) => {
  const { propertyId } = useParams();

  return (
    <>
      <Box display="flex">
        <Typography
          variant="h5"
          color="primary"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Información del cliente
        </Typography>
        <IconButton
          aria-label="houseData"
          data-bs-toggle="modal"
          data-bs-target={"#EditDataClient" + propertyId}
          sx={{ color: "#0053C5" }}
        >
          <EditIcon color="inherit" />
        </IconButton>
        <EditDataClient
          id={propertyId}
          sellerRef={sellerRef}
          refetch={refetch}
        />
      </Box>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="info-building-content"
          id="info-building-header"
        ></AccordionSummary>
        <AccordionDetails> */}
      <Grid container columnSpacing={3} rowSpacing={2} mb={2}>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color={sellerRef.name ? "secondary" : "error"}
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Nombre*
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {sellerRef?.name ?? ""}
            {sellerRef?.name && " "}
            {sellerRef?.fLastName ?? ""}
            {sellerRef?.fLastName && " "}
            {sellerRef?.mLastName ?? ""}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color={sellerRef.email ? "secondary" : "error"}
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Correo electrónico*
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {sellerRef.email ?? "N/A"}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color={sellerRef.phoneNumber ? "secondary" : "error"}
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Teléfono*
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {sellerRef.phoneNumber ?? "N/A"}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color={sellerRef.socialSecurity ? "secondary" : "error"}
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            NSS*
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {sellerRef.socialSecurity ?? "N/A"}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Origen
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {sellerRef.source ?? "N/A"}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Estado Civil*
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {sellerRef.married === true ? "Casado/a" : "Soltero/a"}
          </Typography>
        </Grid>
        {sellerRef.married === true && (
          <Grid
            item
            sm={6}
            md={4}
            lg={3}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
            >
              Bienes Mancomunados
            </Typography>
            <Typography variant="h6" color="secondary" textAlign={"start"}>
              {sellerRef.communityProperty &&
              sellerRef.communityProperty === true
                ? "Si"
                : "No"}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Typography
        variant="h6"
        color="secondary"
        textAlign={"start"}
        width={"100%"}
      >
        <Typography
          variant="h6"
          component={"span"}
          color="secondary"
          fontWeight={"bold"}
        ></Typography>
      </Typography>
      {/* </AccordionDetails>
      </Accordion> */}
    </>
  );
};

export default InfoClient;
