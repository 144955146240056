export const calcCommission = (
  dayOfRequest, //se cuenta desde el primer día del intervalo hasta el día de solicitud
  required //cantidad solicitada por el cliente
) => {
  const yearDays = 365;
  const annualCommission = 0.3; // comisión máxima anual de adelantos

  let netCommissionPercentage = //comision al día de solicitud
    ((annualCommission / yearDays) * (yearDays - dayOfRequest)).toFixed(4);

  let netCommissionAmount = required * netCommissionPercentage; //monto de la comisión
  let total = Number(required) + Number(netCommissionAmount);

  return { netCommissionAmount, netCommissionPercentage, total };
};
