import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

// import { calcOffer } from "../../helpers/calcOffer";
import { stringToCurrency } from "../../../helpers/currencies";
import getOffer from "../../../helpers/offerCalculation";
import { useModal } from "../../../hooks/useModal";
import QuoterModal from "./QuoterModal";

const InfoOffers = ({
  propertyId,
  mBuilding,
  quote,
  offer,
  interval,
  debtCredit,
  debtsRef,
  offerPercentage,
  netOffer,
  status,
  offerAccepted = null,
  refetch
}) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography
          variant="h5"
          className="blue-text"
          textAlign={"left"}
          fontWeight={600}
        >
          Oferta:{" "}
          <Typography
            variant="body1"
            color="secondary"
            component={"span"}
            fontSize={22}
            fontWeight={600}
          >
            {!quote && !offer
              ? "Aún no se genera una oferta"
              : offerAccepted === null
              ? "Se ha generado una oferta pero no se ha aceptado ni rechazado"
              : offerAccepted === false
              ? "La oferta se ha rechazado"
              : "La oferta se ha aceptado"}
          </Typography>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 0 }}
          onClick={onOpenModal}
        >
          Abrir Cotizador
        </Button>
        {open && (
          <QuoterModal
            open={open}
            onCloseModal={onCloseModal}
            propertyId={propertyId}
            offer={offer}
            quote={quote}
            interval={interval}
            offerAccepted={offerAccepted}
            mBuilding={mBuilding}
            status={status}
            refetch={refetch}
          />
        )}
      </Box>
      <Grid container spacing={3} mb={3}>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Valor cotizador:{" "}
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {quote ? stringToCurrency(quote) : " N/A"}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Última oferta:{" "}
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {offer ? stringToCurrency(offer) : " N/A"}
          </Typography>
        </Grid>
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Oferta máxima (365 días):{" "}
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {stringToCurrency(offer ? offer * 0.9 : quote * 0.9) ?? " N/A"}
          </Typography>
        </Grid>
        {interval && (
          <Grid
            item
            sm={6}
            md={4}
            lg={3}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
            >
              Oferta con intervalo ({interval} días):{" "}
            </Typography>
            <Typography variant="h6" color="secondary" textAlign={"start"}>
              {stringToCurrency(getOffer(offer ?? quote, interval)) ?? " N/A"}
            </Typography>
          </Grid>
        )}
        <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
          >
            Deuda Crédito:{" "}
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {stringToCurrency(debtCredit) ?? " N/A"}
          </Typography>
        </Grid>
        {debtsRef.length > 0 && (
          <Grid
            item
            sm={6}
            md={4}
            lg={3}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
            >
              Adeudos:{" "}
            </Typography>
            <Typography variant="h6" color="secondary" textAlign={"start"}>
              {stringToCurrency(
                debtsRef.reduce((subtotal, debt) => subtotal + debt.amount, 0)
              ) ?? " N/A"}
            </Typography>
          </Grid>
        )}
        {offerPercentage && (
          <Grid
            item
            sm={6}
            md={4}
            lg={3}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
            >
              Aumento de oferta (reconversion):{" "}
            </Typography>
            <Typography variant="h6" color="secondary" textAlign={"start"}>
              {Math.round((offerPercentage - 1) * 100) + "%" ?? " N/A"}
            </Typography>
          </Grid>
        )}
      </Grid>
      {netOffer && (
        <>
          <Typography
            variant="h6"
            textAlign={"start"}
            width={"100%"}
            fontWeight={"bold"}
            className="blue-text"
          >
            Oferta Neta (con adeudos y adelantos):{" "}
          </Typography>
          <Typography variant="h6" color="secondary" textAlign={"start"}>
            {stringToCurrency(
              netOffer && netOffer > 0 ? Math.round(netOffer) : 0
            ) ?? " N/A"}
          </Typography>
        </>
      )}
    </>
  );
};

export default InfoOffers;
