import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const searchPostalCode = async (
  // token,
  code
) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/postal-codes?code=${code}`
      // {
      //   headers: {
      //     Authorization: `Bearer ${token}`
      //   }
      // }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
