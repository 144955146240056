import Map from "../../components/Metrics/Map";

const DisplaySection = ({ displayComponent }) => {
  switch (displayComponent) {
    case "general":
      return <h2>seccion de general</h2>;
    case "mapa":
      return (
        <>
          <div className="d-flex w-100 pt-5 mt-5">
            <Map />
          </div>
        </>
      );
    case "funnel":
      return <h2>seccion de funnel</h2>;
    case "kpi":
      return <h2>seccion de kpi</h2>;
    case "cotizacion":
      return <h2>seccion de cotizacion</h2>;
    case "demanda":
      return <h2>seccion de demanda</h2>;
    case "oferta":
      return <h2>seccion de oferta</h2>;
    default:
      return;
  }
};

export default DisplaySection;
