const tasaMin = 0.1; // %
const tasaMax = 0.337; // %
const diasAlAño = 365;
const tasaDiaria = (tasaMax - tasaMin) / diasAlAño; // %

function getPorcentage(days) {
  return tasaMin + (diasAlAño - days) * tasaDiaria;
}

export default function getOffer(propertyValue, days) {
  return Math.round(propertyValue - getPorcentage(days) * propertyValue);
}
