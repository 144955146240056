const { REACT_APP_BASE_URL } = process.env;

export const getUploadFileUrl = (token, path, fileName) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      path,
      fileName
    }).toString();

    const request = new Request(
      REACT_APP_BASE_URL + "/utils/upload-urls?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const getDownloadFileUrl = (token, key) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({ key }).toString();

    const request = new Request(
      REACT_APP_BASE_URL + "/utils/download-urls?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const uploadFile = (url, data) =>
  new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT",
      body: data,
      headers: {
        "Content-Length": new Blob([data]).size
      },
      mode: "cors"
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
