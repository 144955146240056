import CloseIcon from "@mui/icons-material/Close";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";

import { useModal } from "../../hooks/useModal";

const Qr = ({ property, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  const qrRef = useRef();

  const onDownloadQr = () => {
    const svg = qrRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    const padding = 10; // Padding size in pixels

    img.onload = () => {
      canvas.width = img.width + 2 * padding;
      canvas.height = img.height + 2 * padding;
      ctx.fillStyle = "#ffffff"; // Set background color to white
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, padding, padding);

      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");

      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <Button //CREAR MODAL ADEUDOS PENDIENTE, BORRAR CUANDO SE HAGA
        variant="contained"
        onClick={onOpenModal}
      >
        <QrCode2Icon />
      </Button>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ width: { xs: "100%" } }}>
            {property?.shortId && (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{
                    width: { xs: 300 },
                    height: { xs: 300 },
                    padding: "2em",
                    margin: "1.5em 0"
                  }}
                >
                  <QRCode
                    ref={qrRef}
                    value={property?.shortId}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    viewBox={`0 0 256 256`}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    sx={{ mt: 0 }}
                    data-bs-toggle="modal"
                    data-bs-target={`#modalDebts${property?.shortId}`}
                    onClick={onDownloadQr}
                  >
                    Descargar
                  </Button>
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Qr;
