import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { useQuery } from "react-query";

import { getAllAdvisers } from "../../../api/advisers/getAllAdvisers";
import ReassignAdviser from "../../../components/Reconvert/ReassignAdviser";
import TokenContext from "../../../context/Token";

const AdviserInfo = ({ role, adviserRef, refetch, propertyId }) => {
  const { token } = useContext(TokenContext);
  const { data } = useQuery(["advisers"], () => getAllAdvisers(token));

  return (
    <>
      <Box display="flex">
        <Typography
          variant="h5"
          color="primary"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Información del asesor
        </Typography>
        {role === "Super" && (
          <Grid item xs={12} md={6}>
            <ReassignAdviser
              advisers={data?.data?.advisers ?? []}
              refetchReconversion={refetch}
              id={propertyId}
            />
          </Grid>
        )}
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="info-building-content"
          id="info-building-header"
        ></AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} mb={3}>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Nombre
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {adviserRef?.name ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Correo electrónico
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {adviserRef.email ?? "N/A"}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Teléfono
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {adviserRef.phoneNumber ?? "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AdviserInfo;
