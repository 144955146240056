import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { useContext, useState } from "react";

import { updateAddress } from "../../../api/properties/updateAddress";
import { updateProperties } from "../../../api/properties/updateProperties";
// import { getQuoters } from "../../../api/quoters/getQuoters";
import TokenContext from "../../../context/Token";
import PropertyAddress from "../createHouseSeller/PropertyAddress";
import PropertyCharacteristics from "../createHouseSeller/PropertyCharacteristics";
import CheckBoxes from "./CheckBoxes";

//no está hecho para recibir datos iniciales ni actualizar (no put), solo para crear una casa nueva(post)
const ModalDataHouseV2 = ({ property, open, onCloseModal, refetch }) => {
  const { token } = useContext(TokenContext);
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [loading, setLoading] = useState(false);

  const [propertyAddress, setPropertyAddress] = useState({
    numInt: property?.address?.numInt?.toString() ?? "",
    parkingLot: property?.address?.parkingLot?.toString() ?? "",
    levels: property?.address?.levels?.toString() ?? "",
    credit: property?.address?.credit?.toString() ?? "",
    washroom: property?.address?.washroom?.toString() ?? "",
    street: property?.address?.street?.toString() ?? "",
    mLand: property?.address?.mLand?.toString() ?? "",
    bedrooms: property?.address?.bedrooms?.toString() ?? "",
    floor: property?.address?.floor?.toString() ?? "",
    // mortgage: property?.address?.mortgage?.toString() ?? "",
    numExt: property?.address?.numExt?.toString() ?? "",
    mBuilding: property?.address?.mBuilding?.toString() ?? "",
    division: property?.address?.division?.toString() ?? "",
    debt: property?.address?.debt?.toString() ?? "",
    kitchen: property?.address?.kitchen ?? false,
    rooftop: property?.address?.rooftop ?? false,
    spaceBetween: property?.address?.spaceBetween ?? false,
    heater: property?.address?.heater ?? false,
    doorProtection: property?.address?.doorProtection ?? false,
    accessControl: property?.address?.accessControl ?? false,
    halfBath: property?.address?.halfBath ?? false
  });

  const [propertyData, setPropertyData] = useState({
    postalCode: property?.postalCode ?? "",
    state: property?.state ?? "",
    city: property?.city ?? "",
    settlement: property?.settlement ?? "",
    type: property?.type ?? "", //dept, casa o duplex
    adquisitionYear: property?.adquisitionYear ?? "", //fecha de adquisición
    reasonSale: property?.reasonSale ?? "", //razón de venta
    isInhabited: property?.isInhabited ?? true, //si está deshabitada
    wantedForSale: property?.wantedForSale ?? "" //cantidad esperada para vender
  });

  const sendData = async () => {
    try {
      setLoading(true);
      await updateAddress(token, property._id, propertyAddress);

      await updateProperties(token, property._id, propertyData);
      refetch();
      setSuccess({ severity: "success", text: "Actualizado" });
      setLoading(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      console.log(error);
      setSuccess({ severity: "error", text: error });
      setLoading(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"error-success-title"}>
        Informacion del inmueble
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component={"form"} onSubmit={sendData}>
          <PropertyAddress
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
          />
          <PropertyCharacteristics
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
          />
          <CheckBoxes
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {success.severity === "success" && (
          <Alert severity={success.severity} sx={{ mr: 2 }}>
            {success.text}
          </Alert>
        )}
        {success.severity === "error" && (
          <Alert severity={success.severity} sx={{ mr: 2 }}>
            {success.text}
          </Alert>
        )}
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={sendData}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDataHouseV2;
