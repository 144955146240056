import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";

import DebtsTable from "../../../components/Modals/ModalDebts/DebtsTable";
import ModalDebts from "../../../components/Modals/ModalDebts/ModalDebts";
import { useModal } from "../../../hooks/useModal";

const DebtsInfo = ({ property, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Button //CREAR MODAL ADEUDOS PENDIENTE, BORRAR CUANDO SE HAGA
        variant="contained"
        onClick={onOpenModal}
      >
        Adeudos
      </Button>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            Adeudos
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ width: { xs: 300, md: 500 } }}>
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                variant="contained"
                sx={{ mt: 0 }}
                data-bs-toggle="modal"
                data-bs-target={`#modalDebts${property?._id}`}
              >
                Agregar
              </Button>
            </Box>
            <ModalDebts
              showOptions={true}
              id={property?._id}
              debtsRef={property?.debtsRef}
              debts={property?.debts ?? false}
              refetchProperties={refetch}
            />
            <Box
              sx={{
                mt: 1,
                height: 300,
                width: 450,
                border: "1px solid rgba(111, 128, 149, 0.6)",
                borderRadius: 2,
                overflowY: "auto",
                "::-webkit-scrollbar": { width: "5px" },
                "::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",

                  borderRadius: "10px"
                },
                "::-webkit-scrollbar-thumb": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
                  borderRadius: "10px"
                }
              }}
            >
              <DebtsTable
                debtsRef={property?.debtsRef}
                refetchProperties={refetch}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DebtsInfo;
