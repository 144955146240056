import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { createPropertyAppointment } from "../../../api/properties/createPropertyAppointment";
import { updateProperties } from "../../../api/properties/updateProperties";
import TokenContext from "../../../context/Token";

const AppointmentForm = ({
  openModal,
  setOpenModal,
  appointment,
  refetch,
  propertyId
}) => {
  const { token } = useContext(TokenContext);
  const { processStatus } = useParams();
  const navigate = useNavigate();
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    date: appointment.date
      ? moment.utc(appointment.date).format("YYYY-MM-DDTHH:mm")
      : "",
    comments: "sin comentario"
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const sendData = async () => {
    setLoading(true);
    try {
      await createPropertyAppointment(token, propertyId, data);
      setLoading(false);

      if (processStatus !== "cita") {
        setSuccess({
          severity: "success",
          text: "Actualizado, redirigiendo a citas"
        });
        await updateProperties(token, propertyId, { status: "Cita" });
        setTimeout(() => {
          navigate(`/ctc/compra/propiedad/${propertyId}/cita`, {
            replace: true
          });
          setSuccess({ severity: "", text: "" });
        }, 3000);
      } else {
        setSuccess({
          severity: "success",
          text: "Actualizado"
        });
        refetch();
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      setSuccess({ severity: "error", text: error });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby={"error-success-dialogue"}
      maxWidth="md"
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"meeting"}>
        Fecha de Cita
        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display={"flex"} flexDirection={"column"} width={400}>
          <TextField
            type={"datetime-local"}
            onChange={handleChange}
            name="date"
            value={data.date ?? ""}
          />
          {/* <TextField
            multiline
            label="comentarios"
            onChange={handleChange}
            name="comments"
            value={data?.comments ?? ""}
          /> */}
        </Box>
      </DialogContent>
      <DialogActions>
        {success.severity !== "" && (
          <Alert severity={success.severity}>{success.text}</Alert>
        )}
        <Button
          variant="contained"
          onClick={sendData}
          sx={{ mr: 2 }}
          disabled={loading}
        >
          {loading ? "Enviando Datos..." : "Actualizar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentForm;
