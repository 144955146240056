import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { calcCommission } from "../../helpers/comisionAdelanto";
import { stringToCurrency } from "../../helpers/currencies";

const OfferDisplay = ({
  offer,
  rangeDays,
  calculatedPrice,
  debts,
  advancedPayment,
  setAdvancedPayment,
  clientData,
  setClientData,
  totalAll,
  setTotalAll
}) => {
  // console.log(calculatedPrice)

  const [totalDebts, setTotalDebts] = useState(0);

  useEffect(() => {
    const calc = () => {
      const total = Object.values({
        ...debts,
        taxes: clientData.taxes,
        commission: clientData.commission,
        debtCredit: clientData.debtCredit
      })
        .filter((d) => d !== "" && Number(d) !== 0)
        .reduce((suma, val) => suma + Number(val), 0);

      setTotalAll(total);
    };

    calc();
    //eslint-disable-next-line
  }, [debts, clientData.taxes, clientData.commission, clientData.debtCredit]);

  useEffect(() => {
    const calc = () => {
      const total = Object.values({
        ...debts,
        debtCredit: clientData.debtCredit
      })
        .filter((d) => d !== "" && Number(d) !== 0)
        .reduce((suma, val) => suma + Number(val), 0);

      setTotalDebts(total);
    };

    calc();
  }, [debts, advancedPayment, clientData]);

  useEffect(() => {
    const setCommission = () => {
      if (Number(rangeDays) > 180) {
        setClientData({
          ...clientData,
          commission: (totalDebts - clientData.debtCredit) * 0.3
        });
      } else if (Number(rangeDays) <= 180 || totalDebts === 0) {
        setClientData({ ...clientData, commission: 0 });
      }
    };

    setCommission();
    //eslint-disable-next-line
  }, [rangeDays, totalDebts, clientData]);

  useEffect(() => {
    let temp = calcCommission(
      Number(advancedPayment.dayOfRequest),
      Number(advancedPayment.required)
    );

    setAdvancedPayment({
      ...advancedPayment,
      commissionPercentage: temp.netCommissionPercentage,
      commissionAmount: temp.netCommissionAmount,
      total: temp.total
    });
    //eslint-disable-next-line
  }, [advancedPayment.dayOfRequest, advancedPayment.required]);
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Oferta Bruta:{" "}
            </Typography>
            {stringToCurrency(offer)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"center"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Intervalo:{" "}
            </Typography>
            {rangeDays}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Oferta Máxima:{" "}
            </Typography>
            {stringToCurrency(offer * 0.9)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Impuestos:{" "}
            </Typography>
            {stringToCurrency(clientData.taxes)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Oferta total:{" "}
            </Typography>
            {stringToCurrency(calculatedPrice)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Comisión Adelanto:{" "}
            </Typography>
            {stringToCurrency(advancedPayment?.commissionAmount)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Porcentaje Comisión Adelanto:{" "}
            </Typography>
            {(advancedPayment.commissionPercentage * 100).toFixed(2)}%
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Total Adelanto mas Comisión:{" "}
            </Typography>
            {stringToCurrency(advancedPayment.total)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Total Adeudos:{" "}
            </Typography>
            {stringToCurrency(totalDebts)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {clientData.commission > 0 && (
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
            >
              <Typography
                variant="h6"
                fontSize={17}
                component={"span"}
                color="secondary"
                fontWeight={"bold"}
              >
                Comisión adeudos:{" "}
              </Typography>
              {stringToCurrency(clientData.commission)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Total Deducciones:{" "}
            </Typography>
            {stringToCurrency(totalAll)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {calculatedPrice !== "" && Number(calculatedPrice) !== 0 && (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"center"}
              width={"100%"}
              mt={3}
            >
              <Typography
                variant="h6"
                component={"span"}
                color="primary"
                fontWeight={"bold"}
              >
                Oferta Neta:{" "}
              </Typography>
              {calculatedPrice - totalAll - advancedPayment.required > 0
                ? stringToCurrency(
                    calculatedPrice - totalAll - advancedPayment.total
                  )
                : "El percio de compra es menor al valor de las deducciones y/o adelanto"}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </Box>
  );
};

export default OfferDisplay;
