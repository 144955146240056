import { Box, TextField, Typography, Button, Alert, Grid } from "@mui/material";
import { useState } from "react";

const CodeVerification = ({ setShowComponent, showComponent }) => {
  const [data, setData] = useState({ email: "", code: "", password: "" });
  const [pass2, setPass2] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.password !== pass2) {
      setMessage({
        type: "error",
        text: "Las contraseñas no coinciden"
      });
      setTimeout(() => {
        setMessage({
          type: "",
          text: ""
        });
      }, 3000);
      return;
    }

    setMessage({
      type: "success",
      text: "Se ha actualizado la contraseña"
    });
    setTimeout(() => {
      setMessage({
        type: "",
        text: ""
      });
    }, 3000);
  };

  return (
    <Box
      autoComplete="off"
      component={"form"}
      sx={{
        border: "1px solid #8D9AAA",
        borderRadius: 5,
        width: "70%",
        height: 500,
        m: "auto"
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" color="secondary" my={3}>
        Recuperación de contraseña
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            required
            autoComplete="off"
            name="email"
            label="Correo electrónico"
            value={data.email}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            autoComplete="off"
            name="code"
            label="Código"
            value={data.code}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            autoComplete="new-password"
            type="password"
            name="password"
            label="contraseña"
            value={data.password}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            required
            autoComplete="new-password"
            name="pass2"
            label="Repetir contraseña"
            value={pass2}
            onChange={(e) => setPass2(e.target.value)}
            size="small"
            error={data.password !== pass2}
          />
        </Grid>
      </Grid>

      <Box>
        <Button type="submit" variant="contained">
          Enviar
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setShowComponent("form");
          }}
        >
          Solicitar otro código
        </Button>
      </Box>
      {message.type && <Alert severity={message.type}>{message.text}</Alert>}
    </Box>
  );
};

export default CodeVerification;
