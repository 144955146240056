import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { calcOffer } from "../../helpers/calcOffer";
import { stringToCurrency } from "../../helpers/currencies";

const TableInventory = (props) => {
  return (
    <tbody>
      {props.properties?.docs?.map((property) => {
        return (
          <tr key={property._id}>
            <td>
              <label className="property-title m-0">{property._id}</label>
            </td>
            <td>
              <label className="value-title">
                {property.statusSale ?? "Pre-Inventario"}
              </label>
            </td>
            <td>
              <label className="value-title">
                {stringToCurrency(
                  calcOffer(
                    property?.interval ?? 365,
                    property?.quote ?? property?.offer
                  )
                ) ?? " N/A"}
              </label>
            </td>
            <td>
              <label className="value-title">
                {stringToCurrency(property.offer ?? property.quote) ?? ""}
              </label>
            </td>
            <td>
              <label className="value-title">
                {property?.adviserRef?.name ?? "N/A"}
              </label>
            </td>
            <td>
              <Link to={`${property._id}`}>
                <Button variant="contained">Ver Más</Button>
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableInventory;
