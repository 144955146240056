import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  TextField,
  Tooltip
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { useEffect, useState } from "react";

// import { formulaCTC } from "../../helpers/cotizador";
import { searchPostalCode } from "../../api/postalCode/searchPostalCode";
import { getQuoters } from "../../api/quoters/getQuoters";
import Pdf from "../../components/InfoFields/Pdf";
import getOffer from "../../helpers/offerCalculation";
import Debts from "./Debts";
import OfferDisplay from "./OfferDisplay";
import PostalCodeDisplay from "./PostalCodeDisplay";
import QuoterSlider from "./QuoterSlider";

const CotizerContainer = () => {
  const [rangeDays, setRangeDays] = useState(0);
  const [offer, setOffer] = useState(""); //valor inicial al que se le restan cosas
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [totalAll, setTotalAll] = useState(0); //todas las daudas a quitar
  const [generatePdf, setGeneratePdf] = useState(false); //lo hice por si se necesitaba info en los state antes de renderizar el componente de pdf, pero no funcionó
  const [loading, setLoading] = useState(false);
  const [calculatedPrice, setcalculatedPrice] = useState(0); //valor - 10% e intervalo
  const [debtsChecked, setDebtsChecked] = useState(false); //que se vean los adeudos
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [settlements, setSettlements] = useState([]);
  const [selectedSettlement, setSelectedSettlement] = useState("");
  const [mBuilding, setMBuilding] = useState(""); //m2 de construccion
  const [response, setResponse] = useState(null); //lo que llega de consultar para cotizar
  const [advancedPayment, setAdvancedPayment] = useState({
    required: "",
    dayOfRequest: 0,
    commissionPercentage: "",
    commissionAmount: "",
    total: ""
  }); //adelanto, está separado por si se usa con otro endpoint

  const [clientData, setClientData] = useState({
    name: "",
    fLastName: "",
    mLastName: "",
    email: "",
    phoneNumber: "",
    debtCredit: "",
    taxes: 18500,
    commission: ""
  });

  const [debts, setDebts] = useState({
    agua: "",
    luz: "",
    predial: "",
    mantenimiento: ""
  });

  const handleDayOfRequestChange = (e) => {
    const value = e.target.value;

    if (value < rangeDays && value >= 0) {
      setAdvancedPayment({
        ...advancedPayment,
        dayOfRequest: value
      });
    }
  };

  const searchPostalCodePrice = async (e) => {
    e.preventDefault();

    if (postalCode.length !== 5) {
      setSuccess({
        severity: "error",
        text: "El código postal debe ser de 5 números"
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
      return;
    }

    const response = await searchPostalCode(postalCode);

    if (response?.data?.postalCodes && response?.data?.postalCodes[0]?.city) {
      setCity(response.data.postalCodes[0].city);
      setSettlements(response.data.postalCodes[0].settlements);
    }

    setLoading(true);
    try {
      let response = await getQuoters(postalCode, mBuilding ?? 1);

      if (response?.data?.data[0]?.averageValue) {
        setResponse(response?.data?.data[0]);

        if (mBuilding < 40) {
          setOffer(
            Math.ceil(response?.data?.data[0].averageValue * mBuilding * 1.15)
          );
        } else {
          setOffer(Math.ceil(response?.data?.data[0].averageValue * mBuilding));
        }
      } else {
        setResponse({
          averageValue: 10000,
          average_property_price: mBuilding * 10000
        });

        if (mBuilding < 40) {
          setOffer(Math.ceil(10000 * mBuilding * 1.15));
        } else {
          setOffer(Math.ceil(10000 * mBuilding));
        }
      }
      setLoading(false);
      // setResponse({ averagePriceM2: 10000, marketValue: mBuilding * 10000 });
    } catch (error) {
      setLoading(false);
      setSuccess({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };
  const handleClientData = (e) => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  const handleDebts = (e) => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setDebts({ ...debts, [e.target.name]: e.target.value });
  };
  const onChangeRange = (e) => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setRangeDays(e.target.value);
  };

  const clearFields = () => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setClientData({
      name: "",
      fLastName: "",
      mLastName: "",
      email: "",
      phoneNumber: "",
      debtCredit: "",
      taxes: 18500,
      commission: ""
    });
    setDebts({ agua: "", luz: "", predial: "", mantenimiento: "" });
    setAdvancedPayment({
      ...advancedPayment,
      required: 0,
      total: 0,
      commissionPercentage: 0.3,
      commissionAmount: 0,
      dayOfRequest: 0
    });
    setMBuilding("");
    setPostalCode("");
    setOffer("");
    setResponse(null);
    setGeneratePdf(false);
    setSettlements([]);
    setSelectedSettlement("");
    console.log(advancedPayment);
  };

  useEffect(() => {
    const getPrice = () => {
      setcalculatedPrice(getOffer(Number(offer), rangeDays));
    };

    getPrice();
  }, [rangeDays, offer]);

  const saveClientAndDownloadPdf = async () => {
    setGeneratePdf(true);
    try {
      if (
        clientData.name &&
        clientData.fLastName &&
        clientData.mLastName &&
        clientData.email &&
        clientData.phoneNumber
      ) {
        await getQuoters(postalCode, mBuilding ?? 1, {
          seller: { ...clientData, source: "Dashboard" },
          property: { quote: offer }
        });
      }
      setSuccess({
        severity: "success",
        text: "Cliente y cotización generados"
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      setSuccess({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 5,
        border: "1px solid rgba(111, 128, 149, 0.6)",
        borderRadius: 2,
        py: 5
      }}
    >
      {success.severity !== "" && (
        <Snackbar open={success.severity !== ""} autoHideDuration={3000}>
          <Alert severity={success.severity}>{success.text}</Alert>
        </Snackbar>
      )}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            name="name"
            label="Nombre(s)"
            value={clientData.name}
            onChange={handleClientData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            name="fLastName"
            label="Apellido paterno"
            value={clientData.fLastName}
            onChange={handleClientData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            name="mLastName"
            label="Apellido Materno"
            value={clientData.mLastName}
            onChange={handleClientData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            name="email"
            label="e-mail"
            value={clientData.email}
            onChange={handleClientData}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            name="phoneNumber"
            label="Teléfono"
            value={clientData.phoneNumber}
            onChange={handleClientData}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            name="debtCredit"
            label="Deuda Crédito"
            value={clientData.debtCredit}
            onChange={handleClientData}
          />
        </Grid> */}
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            disabled={rangeDays === 0}
            fullWidth
            size="small"
            name="adelanto"
            label="Adelanto"
            value={advancedPayment.required}
            onChange={(e) =>
              setAdvancedPayment({
                ...advancedPayment,
                required: e.target.value
              })
            }
          />
          <Tooltip
            placement="top-end"
            arrow
            title="Solo se desbloquea si el intervalo es mayor a cero días."
            sx={{ fontSize: 18 }}
          >
            <HelpOutlineIcon
              color="primary"
              fontSize="inherit"
              sx={{ mt: 1 }}
            />
          </Tooltip>
        </Grid>
        {/* <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <TextField
            fullWidth
            disabled={rangeDays === 0}
            size="small"
            name="dayOfRequest"
            label="Días para el adelanto"
            value={advancedPayment.dayOfRequest}
            onChange={handleDayOfRequestChange}
          />
          <Tooltip
            placement="top-end"
            arrow
            title="No puede ser mayor que el intervalo."
            sx={{ fontSize: 18 }}
          >
            <HelpOutlineIcon
              color="primary"
              fontSize="inherit"
              sx={{ mt: 1 }}
            />
          </Tooltip>
        </Grid> */}
      </Grid>
      <PostalCodeDisplay
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        mBuilding={mBuilding}
        setMBuilding={setMBuilding}
        response={response}
        setResponse={setResponse}
        searchPostalCodePrice={searchPostalCodePrice}
        clearFields={clearFields}
        settlements={settlements}
        selectedSettlement={selectedSettlement}
        setSelectedSettlement={setSelectedSettlement}
        loading={loading}
      />
      <Box display={"flex"}>
        <FormControlLabel
          control={
            <Switch
              value={debtsChecked}
              onChange={() => setDebtsChecked(!debtsChecked)}
            />
          }
          label="Mostrar extras"
        />
      </Box>
      {debtsChecked && (
        <Debts
          debts={debts}
          clientData={clientData}
          setClientData={setClientData}
          handleDebts={handleDebts}
          advancedPayment={advancedPayment}
          setAdvancedPayment={setAdvancedPayment}
        />
      )}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id=""
            label="Introduce oferta"
            value={offer}
            onChange={(e) => setOffer(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <QuoterSlider
            onChangeRange={onChangeRange}
            rangeDays={rangeDays}
            newOffer={offer}
          />
        </Grid>
      </Grid>
      <OfferDisplay
        debts={debts}
        clientData={clientData}
        setClientData={setClientData}
        offer={offer}
        rangeDays={rangeDays}
        calculatedPrice={calculatedPrice}
        advancedPayment={advancedPayment}
        setAdvancedPayment={setAdvancedPayment}
        totalAll={totalAll}
        setTotalAll={setTotalAll}
      />
      {generatePdf === false && calculatedPrice - totalAll > 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => saveClientAndDownloadPdf()}
        >
          Generar PDF
        </Button>
      )}
      {generatePdf === true && calculatedPrice - totalAll > 0 && (
        <Button variant="contained" color="primary">
          <PDFDownloadLink
            style={{ textDecoration: "none", color: "#fff" }}
            document={
              <Pdf
                source={"dashboard-quoter"}
                property={{
                  //imita el formato de property para no crear otro componente ni editar éste
                  sellerRef: {
                    name: clientData?.name,
                    fLastName: clientData?.fLastName,
                    mLastName: clientData?.mLastName,
                    email: clientData?.email
                  },
                  advancedPayment: advancedPayment.total,
                  offer: calculatedPrice,
                  netOffer: Number(
                    calculatedPrice - totalAll - advancedPayment.total
                  ),
                  debtCredit: clientData.debtCredit,
                  postalCode,
                  city,
                  settlement: selectedSettlement
                }}
                debtsRef={[
                  //sigue el formato de "ver más"
                  { type: "agua", amount: debts.agua },
                  { type: "mantenimiento", amount: debts.mantenimiento },
                  { type: "luz", amount: debts.luz },
                  { type: "predial", amount: debts.predial },
                  { type: "mantenimiento", amount: debts.mantenimiento },
                  { type: "impuestos", amount: clientData.taxes }
                ]}
              />
            }
            fileName={`Oferta ${clientData?.name} ${
              clientData?.fLastName ?? ""
            } ${moment().format("DD-MM-YYYY")}`}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Cargando..." : "Descargar PDF"
            }
          </PDFDownloadLink>
        </Button>
      )}
    </Container>
  );
};

export default CotizerContainer;
