import { Box, TextField, Typography, Button, Alert } from "@mui/material";
import { useState } from "react";

const PasswordForm = ({ setShowComponent }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });

  const handleSubmit = (e) => {
    e.preventDefault();

    setMessage({
      type: "success",
      text: "Se ha enviado un mensaje a tu correo"
    });
    setTimeout(() => {
      setMessage({
        type: "",
        text: ""
      });
    }, 3000);
  };

  return (
    <Box
      component={"form"}
      sx={{
        border: "1px solid #8D9AAA",
        borderRadius: 5,
        width: "70%",
        height: 500,
        m: "auto"
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" color="secondary" my={3}>
        Recuperación de contraseña
      </Typography>
      <Box display={"flex"} width={"fit-content"} margin={"auto"} gap={2}>
        <TextField
          required
          name="email"
          label="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="small"
        />
        <Button variant="text" color="primary" type="submit">
          Enviar
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => {
            setShowComponent("code");
          }}
        >
          Ya tengo un código
        </Button>
      </Box>
      {message.type && <Alert severity={message.type}>{message.text}</Alert>}
    </Box>
  );
};

export default PasswordForm;
