import { Grid, Typography } from "@mui/material";
import moment from "moment";

const ExtraInfo = ({ property }) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={2}>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Cocina con Tarja
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.kitchen === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Tiene Zotehuela
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.rooftop === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Espacio para sala/comedor
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.spaceBetween === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Tiene Calentador
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.heater === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Herrería en puertas de entrada
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.doorProtection === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Tiene Control de Acceso
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.accessControl === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Tiene medio baño
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {property?.address?.halfBath === true ? "Si" : "No"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={3} display="flex" flexDirection={"column"}>
        <Typography
          variant="h6"
          color="secondary"
          textAlign={"start"}
          width={"100%"}
          fontWeight={"bold"}
        >
          Fecha de creación
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {moment(property?.createdAt).format("DD/MM/YYYY [a las] hh:mm a")}
        </Typography>
        <Typography variant="h6" color="secondary" textAlign={"start"}>
          {moment(property?.createdAt).fromNow()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ExtraInfo;
