import { Grid, TextField, Typography } from "@mui/material";

import PostalCodeInfo from "./PostalCodeInfo";

const PropertyAddress = ({
  propertyData,
  setPropertyData,
  propertyAddress,
  setPropertyAddress,
  setAvgPrice
}) => {
  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        fontSize={20}
        fontWeight="bold"
      >
        Ubicación
      </Typography>
      <Grid container spacing={2}>
        <PostalCodeInfo
          propertyData={propertyData}
          setPropertyData={setPropertyData}
          setAvgPrice={setAvgPrice}
        />
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            id="street"
            name="street"
            label="Calle"
            value={propertyAddress.street ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            id="numExt"
            name="numExt"
            label="Numero Exterior"
            value={propertyAddress.numExt ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            id="numInt"
            name="numInt"
            label="Numero Interior"
            value={propertyAddress.numInt ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            id="mzn"
            name="mzn"
            label="Manzana (si aplica)"
            value={propertyAddress.mzn ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            id="lt"
            name="lt"
            label="Lote (si aplica)"
            value={propertyAddress.lt ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            id="division"
            name='division'
            label="Fraccionamiento (si aplica)"
            value={propertyAddress.division}
            onChange={e => setPropertyAddress({ ...propertyAddress, [e.target.name]: e.target.value })}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default PropertyAddress;
