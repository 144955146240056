import { Box, Pagination } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getProperties } from "../../api/properties/getProperties";
import Loading from "../../components/loading/Loading";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const InventoryContainer = (props) => {
  const [results] = useState(20);
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);
  const [status] = useState("Inventario");

  const { token } = useContext(TokenContext);
  const { data, isLoading, isRefetching } = useQuery(
    ["Inventory-properties", token, results, page, status],
    () => getProperties(token, results, page, status),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  useEffect(() => {
    const getPaginationValue = () => {
      setPaginationValue(Math.ceil(data?.properties?.totalDocs / results));
    };

    getPaginationValue();
  }, [data?.properties?.totalDocs, results]);

  const housesFilter = data?.properties?.docs
    ?.filter((house) => house.statusSale === props.selectTab)
    .map((house) => house);

  return (
    <>
      <Box mt={2} height={32}>
        {paginationValue > 0 && (
          <Pagination
            color="primary"
            count={paginationValue}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        )}
      </Box>
      {!isLoading && !isRefetching && !props.isUpdating ? (
        <Table
          columnTitles={[
            "Id de la Casa",
            "Status",
            "Precio Compra",
            "Precio Venta",
            "Asesores",
            "Más"
          ]}
          properties={props.selectTab ? housesFilter : data?.properties ?? []}
          tableType={"Inventario"}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default InventoryContainer;
