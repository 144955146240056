import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";

import { updatePropertyAppointment } from "../../../../api/properties/updatePropertyAppointment";
import TokenContext from "../../../../context/Token";
import { useModal } from "../../../../hooks/useModal";

const AppointmentResolution = ({
  buttonText,
  actionType,
  modalTitle,
  refetch,
  propertyId,
  appointment
}) => {
  const { token } = useContext(TokenContext);

  const { open, onOpenModal, onCloseModal } = useModal();
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");
  const appointmentDone = async (action) => {
    setLoading(true);

    try {
      await updatePropertyAppointment(token, propertyId, appointment._id, {
        status: action,
        comments: comments
      });

      setLoading(false);
      setSuccess({ severity: "success", text: "Actualizado" });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
        refetch();
      }, 3000);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setSuccess({ severity: "error", text: error });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <>
      <Button sx={{ my: 1 }} onClick={onOpenModal}>
        {buttonText}
      </Button>
      {open && (
        <Dialog
          open={open}
          aria-labelledby={"error-success-dialogue"}
          maxWidth="md"
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"meeting"}>
            {modalTitle}
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box display={"flex"} flexDirection={"column"} width={400}>
              <TextField
                multiline
                label="comentarios"
                onChange={(e) => setComments(e.target.value)}
                name="comments"
                value={comments ?? ""}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {success.severity !== "" && (
              <Alert severity={success.severity}>{success.text}</Alert>
            )}
            <Button
              variant="contained"
              onClick={() => appointmentDone(actionType, comments)}
              sx={{ mr: 2 }}
              disabled={loading}
            >
              {loading ? "Enviando Datos..." : "Actualizar"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AppointmentResolution;
