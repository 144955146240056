import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect } from "react";

const PropertyCharacteristics = ({
  setRenovation,
  propertyData,
  setPropertyData,
  propertyAddress,
  setPropertyAddress
}) => {
  useEffect(() => {
    const setDuplex = () => {
      if (propertyData.type === "Duplex") {
        setPropertyAddress({ ...propertyAddress, levels: "2" });
      }

      if (propertyData.type === "Departamento") {
        setPropertyAddress({ ...propertyAddress, levels: "0" });
      }
    };

    setDuplex();
    //eslint-disable-next-line
  }, [propertyData.type]);
  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        fontSize={20}
        fontWeight="bold"
        mt={4}
      >
        Características
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            required
            id="type"
            name="type"
            label="Tipo de inmueble"
            value={propertyData.type ?? ""}
            onChange={(e) =>
              setPropertyData({ ...propertyData, type: e.target.value })
            }
          >
            <MenuItem value="Casa">casa</MenuItem>
            <MenuItem value="Departamento">departamento</MenuItem>
            <MenuItem value="Duplex">duplex</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            required
            id="parkingLot"
            name="parkingLot"
            label="Espacios de estacionamiento"
            value={propertyAddress.parkingLot ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            id="mLand"
            name="mLand"
            label="M² de terreno"
            value={propertyAddress.mLand ?? ""}
            onChange={(e) =>
              setPropertyAddress({ ...propertyAddress, mLand: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            id="mBuilding"
            name="mBuilding"
            label="M² de construcción"
            value={propertyAddress.mBuilding ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                mBuilding: e.target.value
              })
            }
          />
        </Grid>

        {(propertyData.type === "Casa" || propertyData.type === "Duplex") && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              disabled={propertyData.type === "Duplex"}
              select
              id="levels"
              name="levels"
              label="Pisos/niveles"
              value={
                propertyData.type === "Duplex"
                  ? "2"
                  : propertyAddress.levels ?? ""
              }
              onChange={(e) =>
                setPropertyAddress({
                  ...propertyAddress,
                  [e.target.name]: e.target.value
                })
              }
            >
              <MenuItem value="0">0</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
            </TextField>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            id="bedrooms"
            name="bedrooms"
            label="Numero de cuartos"
            value={propertyAddress.bedrooms ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </TextField>
        </Grid>
        {propertyData.type === "Departamento" && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              select
              id="floor"
              name="floor"
              label="En qué piso está?"
              value={propertyAddress.floor ?? ""}
              onChange={(e) =>
                setPropertyAddress({
                  ...propertyAddress,
                  [e.target.name]: e.target.value
                })
              }
            >
              <MenuItem value="0">0</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
            </TextField>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            id="washroom"
            name="washroom"
            label="Baños completos"
            value={propertyAddress.washroom ?? ""}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                [e.target.name]: e.target.value
              })
            }
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            id="adquisitionYear"
            name="adquisitionYear"
            label="¿Cuándo la adquiriste? (año)"
            value={propertyData.adquisitionYear ?? ""}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                adquisitionYear: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            id="reasonSale"
            name="reasonSale"
            label="Razón de venta"
            value={propertyData.reasonSale ?? ""}
            onChange={(e) =>
              setPropertyData({ ...propertyData, reasonSale: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            select
            id="isInhabited"
            name="isInhabited"
            label="La propiedad está deshabitada"
            value={propertyData.isInhabited ?? true}
            onChange={(e) =>
              setPropertyData({ ...propertyData, isInhabited: e.target.value })
            }
          >
            <MenuItem value={true}>Sí</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            type={"number"}
            id="wantedForSale"
            name="wantedForSale"
            label="¿Cuánto deseas por la casa?"
            value={propertyData.wantedForSale ?? ""}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                wantedForSale: e.target.value
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyCharacteristics;
