import { Alert, MenuItem, Snackbar, TextField } from "@mui/material";
import { useContext, useState } from "react";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

const LeadStatusSelect = (props) => {
  const { token } = useContext(TokenContext);
  const [option, setOption] = useState(props.lead);
  const [success, setSuccess] = useState({ severity: "", text: "" });

  const handleChange = async (option) => {
    setOption(option);
    try {
      await updateProperties(token, props.propertyId, { lead: option });
      setSuccess({ severity: "success", text: "Actualizado" });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      setSuccess({ severity: "error", text: error });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <div className="col-6 p-0">
      <TextField
        size="small"
        fullWidth
        select
        label="Estado del lead"
        name="lead"
        value={option}
        onChange={(e) => handleChange(e.target.value)}
      >
        <MenuItem hidden value={""}>
          Status del lead
        </MenuItem>
        <MenuItem value="Nuevo">Nuevo</MenuItem>
        <MenuItem value="ICSE">ICSE</MenuItem>
        <MenuItem value="Apto">Apto</MenuItem>
        <MenuItem value="No Apto">No Apto</MenuItem>
      </TextField>

      {success.severity !== "" && (
        <Snackbar
          open={success.severity !== ""}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={success.severity}>{success.text}</Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default LeadStatusSelect;
