import React, { useContext } from "react";
import "./styles/Sidebar.css";
import { useState, useEffect } from "react";
import {
  MdAnalytics,
  MdOutlineAssignmentInd,
  MdOutlineSource,
  MdOutlineAssignmentReturn,
  MdDvr,
  MdComputer
} from "react-icons/md";
import { RiPriceTag2Line } from "react-icons/ri";

import TokenContext from "../context/Token";
import CustomLink from "./CustomLink";

const { REACT_APP_VENTA_URL } = process.env;
const MainSidebar = () => {
  const [mytoken, setMyToken] = useState("");
  const { userType } = useContext(TokenContext);

  useEffect(() => {
    const token = window.localStorage.getItem("token_CTC");

    setMyToken(token);
  }, []);

  return (
    <section className="sidebar-section-component">
      <section className="navigate-section-container">
        {userType !== "Lawyer" && (
          <>
            <CustomLink to="/ctc/dashboard/metricas">
              <div className="navigate-icon-link">
                <MdAnalytics className="nav-icon-size fs-5" />
                <label style={{ fontSize: "13px" }}>Métricas</label>
              </div>
            </CustomLink>
            <hr className="divider" />
          </>
        )}
        {userType !== "Lawyer" && (
          <>
            <CustomLink to="/ctc/dashboard/cotizador">
              <div className="navigate-icon-link">
                <MdComputer className="nav-icon-size fs-5" />
                <label style={{ fontSize: "13px" }}>Cotizador</label>
              </div>
            </CustomLink>
            <hr className="divider" />
          </>
        )}
        <CustomLink
          to={
            userType === "Lawyer"
              ? "/ctc/compra/proceso-de-compra"
              : "/ctc/compra/prospectos"
          }
        >
          <div className="navigate-icon-link">
            <MdOutlineAssignmentInd className="nav-icon-size fs-5" />
            <label style={{ fontSize: "13px" }}>Comprar</label>
          </div>
        </CustomLink>

        <hr className="divider" />
        <a
          href={`${REACT_APP_VENTA_URL}/login/?token=${mytoken}&userType=admins`}
          target={"_blank"}
          style={{ color: "rgb(206, 204, 204)", textDecoration: "none" }}
          rel="noreferrer"
        >
          <div className="navigate-icon-link">
            <RiPriceTag2Line className="nav-icon-size fs-5" />
            <label style={{ fontSize: "13px" }}>Vender</label>
          </div>
        </a>
        {userType !== "Lawyer" && (
          <>
            <hr className="divider" />
            <CustomLink to="/ctc/dashboard/inventario">
              <div className="navigate-icon-link">
                <MdOutlineAssignmentReturn className="nav-icon-size fs-5" />
                <label style={{ fontSize: "13px" }}>Inventario</label>
              </div>
            </CustomLink>
          </>
        )}
        {userType !== "Lawyer" && (
          <>
            <hr className="divider" />
            <CustomLink to="/ctc/dashboard/configuracion">
              <div className="navigate-icon-link">
                <MdOutlineSource className="nav-icon-size fs-5" />
                <label style={{ fontSize: "13px" }}>Configuración</label>
              </div>
            </CustomLink>
          </>
        )}
        {/* <hr className="divider" />
        <CustomLink to="/ctc/dashboard/inspecciones">
          <div className="navigate-icon-link">
            <MdPendingActions className="nav-icon-size fs-5" />
            <label style={{ fontSize: "13px" }}>Inspecciones</label>
          </div>
        </CustomLink> */}
        {userType !== "Lawyer" && (
          <>
            <hr className="divider" />
            <CustomLink to="/ctc/dashboard/escrituracion">
              <div className="navigate-icon-link">
                <MdDvr className="nav-icon-size fs-5" />
                <label style={{ fontSize: "13px" }}>Escrituración</label>
              </div>
            </CustomLink>
          </>
        )}
      </section>
    </section>
  );
};

export default MainSidebar;
