import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

import ModalCredit from "../../components/Modals/ModalCredit/ModalCredit";
import { stringToCurrency } from "../../helpers/currencies";

const InfoCredit = ({
  creditType,
  credit,
  debtCredit,
  monthBacklogs,
  hasBacklogCredit,
  propertyId,
  sellerId,
  refetch
}) => {
  return (
    <>
      <Box display={"flex"}>
        <Typography
          variant="h5"
          color="primary"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Información del crédito
        </Typography>
        <IconButton
          data-bs-toggle="modal"
          data-bs-target={"#modalCredit" + propertyId}
          sx={{ color: "#0053C5" }}
        >
          <EditIcon color="inherit" />
        </IconButton>
        <ModalCredit
          credit={credit ?? ""}
          creditType={creditType ?? ""}
          debtCredit={debtCredit ?? ""}
          hasBacklogCredit={hasBacklogCredit ?? ""}
          monthBacklogs={monthBacklogs ?? ""}
          id={propertyId}
          sellerId={sellerId}
          refetch={refetch}
        />
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="info-building-content"
          id="info-building-header"
        ></AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={3} mb={2}>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color={creditType ? "secondary" : "error"}
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Tipo*{" "}
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {creditType ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color={credit ? "secondary" : "error"}
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Número de Crédito*{" "}
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {credit ?? ""}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color={debtCredit ? "secondary" : "error"}
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Adeudo pendiente*{" "}
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {stringToCurrency(debtCredit) ?? ""}
              </Typography>
            </Grid>
            {hasBacklogCredit && (
              <Grid
                item
                sm={6}
                md={4}
                lg={3}
                display="flex"
                flexDirection={"column"}
              >
                <Typography
                  variant="h6"
                  color="secondary"
                  textAlign={"start"}
                  width={"100%"}
                  fontWeight={"bold"}
                >
                  Pagos pendientes:{" "}
                </Typography>
                <Typography variant="h6" color="secondary" textAlign={"start"}>
                  {Number(monthBacklogs) < 13 ? monthBacklogs : "13 o más"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default InfoCredit;
