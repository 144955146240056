import { Grid, TextField } from "@mui/material";

const Debts = ({
  debts,
  handleDebts,
  advancedPayment,
  setAdvancedPayment,
  clientData,
  setClientData
}) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          name="agua"
          label="Agua"
          value={debts.agua}
          onChange={handleDebts}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          name="luz"
          label="Luz"
          value={debts.luz}
          onChange={handleDebts}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          name="predial"
          label="Predial"
          value={debts.predial}
          onChange={handleDebts}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          name="credit"
          label="Crédito"
          value={clientData.debtCredit}
          onChange={(e) =>
            setClientData({ ...clientData, debtCredit: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          name="mantenimiento"
          label="Mantenimiento"
          value={debts.mantenimiento}
          onChange={handleDebts}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          size="small"
          name="taxes"
          label="Impuestos"
          value={clientData.taxes}
          onChange={(e) =>
            setClientData({ ...clientData, taxes: e.target.value })
          }
        />
      </Grid>
    </Grid>
  );
};

export default Debts;
