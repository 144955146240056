import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Grid,
  Typography,
  Slider,
  Box,
  Alert
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { updateProperties } from "../../../api/properties/updateProperties";
import TokenContext from "../../../context/Token";
import { stringToCurrency } from "../../../helpers/currencies";
import getOffer from "../../../helpers/offerCalculation";

const QuoterModal = ({
  open,
  onCloseModal,
  propertyId,
  mBuilding = 0,
  status,
  offer,
  quote,
  interval,
  offerAccepted,
  refetch
}) => {
  const { token } = useContext(TokenContext);

  const [rangeDays, setRangeDays] = useState(365); //intervalo
  const [commercialValue, setCommercialValue] = useState(""); //valor antes que se le descuenta 10% y lo del intervalo
  const [newOffer, setNewOffer] = useState(""); //valor posterior a los descuentos para ofertar al cliente
  const [homeValue, setHomeValue] = useState({}); //state donde se guardarían los valores promedio de casas del cotizador
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [loadingAcceptOffer, setLoadingAcceptOffer] = useState(false);
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const onChangeRange = (e) => {
    setRangeDays(e.target.value > 0 ? e.target.value : 1);
  };

  //Función que trae los valores de la base de datos para cotizar, por ahora no se usa
  // const getHomeValue = async (postalCode, mBuilding) => {
  //   const res = await getQuoters(postalCode, mBuilding);

  //   setHomeValue(res?.data?.data[0]);
  // };

  const sendData = async () => {
    try {
      setLoadingSendData(true);
      await updateProperties(token, propertyId, {
        offer: Number(commercialValue),
        interval: Number(rangeDays) ?? 365,
        newOffer: true,
        offer_accepted: false
      });
      setLoadingSendData(false);
      setSuccess({ severity: "success", text: "Actualizado" });
      refetch();
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (err) {
      console.log(err);
      setSuccess({ severity: "error", text: err });
      setLoadingSendData(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  const acceptOfferManually = async () => {
    try {
      setLoadingAcceptOffer(true);
      await updateProperties(token, propertyId, {
        offer_accepted: true,
        offer: offer || quote,
        interval: rangeDays,
        newOffer: false
      });
      setLoadingAcceptOffer(false);
      setSuccess({ severity: "success", text: "Actualizado" });
      refetch();
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
        onCloseModal();
      }, 3000);
    } catch (error) {
      setSuccess({ severity: "error", text: error });
      setLoadingAcceptOffer(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  const sendToNextStep = async () => {
    try {
      setLoadingNextStep(true);
      await updateProperties(token, propertyId, {
        status: "Expediente"
      });

      setLoadingNextStep(false);
      setSuccess({ severity: "success", text: "Actualizado" });
      refetch();
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
        onCloseModal();
      }, 3000);
    } catch (error) {
      setSuccess({ severity: "error", text: error });
      setLoadingNextStep(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  useEffect(() => {
    if (rangeDays !== 0) {
      // const result = Math.round(quote - (quote * ((0.2599 / 365) * (365 - rangeDays))));
      // const result = formulaCTC(newOffer * 0.9, rangeDays); //<--controla el descontar 10%
      // const result = formulaCTC(newOffer, rangeDays);
      const result = getOffer(commercialValue, rangeDays);

      setNewOffer(result);
    }
  }, [rangeDays, commercialValue]);

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"meeting"}>
        Cotizador
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="body1"
              color="secondary"
            >
              M² Construcción: {mBuilding ?? ""}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="body1"
              color="secondary"
            >
              Precio M²:{" "}
              {
                stringToCurrency(10000)
                // stringToCurrency(homeValue?.averagePriceM2 ?? "")
              }{" "}
            </Typography>
          </Grid>
          {(offer || quote) && (
            <Grid item xs={12} sm={6}>
              <Typography
                textAlign={"left"}
                mb={1}
                variant="body1"
                color="secondary"
              >
                Valor comercial actual:{" "}
                {
                  stringToCurrency(offer ?? quote)
                  // stringToCurrency(homeValue?.marketValue ?? "")
                }{" "}
              </Typography>
            </Grid>
          )}
          {interval && (
            <Grid item xs={12} sm={6}>
              <Typography
                textAlign={"left"}
                mb={1}
                variant="body1"
                color="secondary"
              >
                Intervalo Actual: {interval} Días
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="body1"
              color="secondary"
            >
              Valor comercial sugerido:{" "}
              {
                stringToCurrency(mBuilding * 10000)
                // stringToCurrency(homeValue?.marketValue ?? "")
              }{" "}
            </Typography>
          </Grid>
        </Grid>
        <TextField
          fullWidth
          size="small"
          type="number"
          name="value"
          label="Valor Comercial"
          value={commercialValue}
          onChange={(e) => setCommercialValue(e.target.value)}
        />
        <Typography
          variant="h5"
          color="secondary"
          fontWeight="bold"
          ml={2}
          mb={2}
          textAlign={"center"}
        >
          Valor de Compra:{" "}
          <Typography
            component={"span"}
            variant="h5"
            color="secondary"
            fontWeight={500}
          >
            {stringToCurrency(newOffer)}
          </Typography>
        </Typography>
        <Box width={"80%"} margin={"auto"}>
          <Slider
            aria-label="Intervalo"
            valueLabelDisplay="auto"
            min={0}
            max={365}
            step={30}
            marks
            color="primary"
            value={rangeDays ?? 365}
            onChange={onChangeRange}
          />

          <Typography
            variant="h6"
            color="secondary"
            fontWeight="bold"
            textAlign={"center"}
          >
            Intervalo:{" "}
            <Typography
              component={"span"}
              variant="h6"
              color="secondary"
              fontWeight={500}
            >
              {rangeDays} Días
            </Typography>
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            onClick={sendData}
            disabled={
              loadingSendData ||
              (commercialValue !== "" &&
                (offer && offer > 0
                  ? Number(commercialValue) === offer
                  : Number(commercialValue) === quote) &&
                interval === Number(rangeDays))
            }
          >
            Actualizar Oferta
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 2, pb: 2 }}>
        {commercialValue !== "" &&
        (offer && offer > 0 && success.severity === ""
          ? Number(commercialValue) === offer
          : Number(commercialValue) === quote) &&
        interval === Number(rangeDays) ? (
          <Alert severity="warning">
            Los valores de oferta e intervalo no pueden ser iguales a los más
            recientes registrados
          </Alert>
        ) : null}
        {success.severity !== "" && (
          <Alert severity={success.severity}>{success.text}</Alert>
        )}
        {status === "Prospectos" && offerAccepted && (
          <Grid item xs={12} md={6}>
            <Button
              variant="blue"
              disabled={loadingNextStep}
              sx={{ mb: 0 }}
              onClick={sendToNextStep}
            >
              Enviar a Expediente
            </Button>
          </Grid>
        )}
        {(!offerAccepted || offerAccepted === false) && (
          <Button
            variant="blue"
            onClick={acceptOfferManually}
            disabled={loadingAcceptOffer}
          >
            Aceptar oferta manualmente
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default QuoterModal;
