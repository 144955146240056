import { Box, Stack } from "@mui/material";
import moment from "moment";

import AppointmentResolution from "./AppointmentResolution";

const AfterAppointmentOptions = ({ propertyId, appointment, refetch }) => {
  return (
    <Box display={"flex"} justifyContent={"start"}>
      {moment(appointment?.date).isSameOrBefore(moment().format()) &&
        appointment.status === "Pendiente" && (
          <Stack direction={"row"} spacing={3}>
            <AppointmentResolution
              appointment={appointment}
              propertyId={propertyId}
              refetch={refetch}
              buttonText={"Cita Efectuada"}
              actionType={"Éxito"}
              modalTitle={"Comentarios de la cita"}
            />

            <AppointmentResolution
              appointment={appointment}
              propertyId={propertyId}
              refetch={refetch}
              buttonText={"Cita Fallida"}
              actionType={"Falló"}
              modalTitle={"Razón del fallo"}
            />
            <AppointmentResolution
              appointment={appointment}
              propertyId={propertyId}
              refetch={refetch}
              buttonText={"Cita Cancelada"}
              actionType={"Cancelada"}
              modalTitle={"Razón de la cancelación"}
            />
          </Stack>
        )}
    </Box>
  );
};

export default AfterAppointmentOptions;
