import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { MdFaceUnlock, MdPassword } from "react-icons/md";
import { Link } from "react-router-dom";

import { login, loginAdvisers, loginLawyers } from "../../api/session/login";
import house from "../../assets/AdobeStock_83414840.jpeg";
import ctcLogo from "../../assets/Logo_CTC.svg";
import TokenContext from "../../context/Token";

const Login = () => {
  const { setToken, setUserType: setUserTypeContext } =
    useContext(TokenContext);
  const [userType, setUserType] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState({
    email: "",
    password: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (userType === "admin") {
      login(data)
        .then((response) => {
          localStorage.setItem("token_CTC", response.token);
          setToken(response.token);
          localStorage.setItem("UT", "Admin");
          setUserTypeContext("Admin");
        })
        .catch((err) => {
          err.json().then((e) => {
            console.log(e);
            setError(e.error.message);
            return setTimeout(() => {
              setError("");
            }, 3000);
          });
        })
        .finally(() => setIsLoading(false));
    } else if (userType === "lawyer") {
      loginLawyers(data)
        .then((response) => {
          localStorage.setItem("token_CTC", response.token);
          setToken(response.token);
          localStorage.setItem("UT", "Lawyer");
          setUserTypeContext("Lawyer");
        })
        .catch((err) => {
          err.json().then((e) => {
            console.log(e);
            setError(e.error.message);
            return setTimeout(() => {
              setError("");
            }, 3000);
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      loginAdvisers(data)
        .then((response) => {
          localStorage.setItem("token_CTC", response.token);
          setToken(response.token);
          localStorage.setItem("UT", "Adviser");
          setUserTypeContext("Adviser");
        })
        .catch((err) => {
          err.json().then((e) => {
            console.log(e);
            setError(e.error.message);
            return setTimeout(() => {
              setError("");
            }, 3000);
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <section className="d-flex justify-content-between">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section className="d-flex flex-column w-50 h-auto align-items-center justify-content-center">
        <img src={ctcLogo} alt="logo" className="w-50" />
        <label className="txt-weight-bold fs-2 mt-4">
          ¡Bienvenido de nuevo!
        </label>
        <label className="txt-weight-bold fs-5">Ingresa con tu usuario</label>

        <section className="d-flex flex-column w-75">
          <div className="d-flex flex-column mt-5">
            <label className="txt-weight-bold fs-6 text-start mb-2">
              Usuario
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <MdFaceUnlock className="fs-4" />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="usuario@correo.com"
                aria-label="email"
                value={data.email}
                name="email"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="d-flex flex-column mt-3">
            <label className="txt-weight-bold fs-6 text-start mb-2">
              Contraseña
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <MdPassword className="fs-4" />
              </span>
              <input
                type="password"
                className="form-control"
                placeholder="Contraseña"
                aria-label="Password"
                name="password"
                value={data.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                InputProps={{ style: { borderRadius: "8px" } }}
                required
                select
                label="Tipo de cliente"
                type="userType"
                name="userType"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                color="secondary"
                fullWidth
              >
                <MenuItem value={"adviser"}>Asesor</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"lawyer"}>Abogado</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Box>
            <Typography variant="body1" color="secondary" fontSize={12}>
              ¿Olvidaste tu contraseña?{" "}
              <Typography
                variant="body1"
                color="initial"
                component={"span"}
                fontSize={12}
              >
                <Link to={"/recuperar-password"}>Recuperar</Link>
              </Typography>
            </Typography>
          </Box>
          <Button
            variant="contained"
            disabled={!data.email || !data.password || !userType}
            onClick={handleSubmit}
          >
            Iniciar sesión
          </Button>
          {error && (
            <p className="bg-danger text-white py-1 mt-1 rounded">{error}</p>
          )}
        </section>
      </section>

      <img
        src={house}
        alt="casa"
        className="w-50 vh-100"
        style={{ objectFit: "cover" }}
      />
    </section>
  );
};

export default Login;
