import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";

import CotizerContainer from "../../../pages/cotizer/CotizerContainer";

const CotizerModal = ({ open, onCloseModal }) => {
  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      maxWidth="md"
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"error-success-title"} p={1}>
        Cotizador
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CotizerContainer />
      </DialogContent>
    </Dialog>
  );
};

export default CotizerModal;
