import React, { useContext } from "react";
import "./styles/Sidebar.css";
import {
  MdOutlineSource,
  MdPendingActions,
  MdOutlineAssignmentReturn,
  MdOutlineAssignmentInd
} from "react-icons/md";
import { RiPriceTag2Line, RiGroup2Line } from "react-icons/ri";
import { VscLaw } from "react-icons/vsc";

import TokenContext from "../context/Token";
import CustomLink from "./CustomLink";

const Sidebar = () => {
  const { userType } = useContext(TokenContext);

  return (
    <section className="sidebar-section-component">
      <section className="navigate-section-container">
        {(userType === "Admin" || userType === "Adviser") && (
          <>
            <CustomLink to="/ctc/compra/prospectos">
              <div className="navigate-icon-link">
                <MdOutlineAssignmentInd className="nav-icon-size" />
                Prospectos
              </div>
            </CustomLink>
            <hr className="divider" />
          </>
        )}
        {(userType === "Admin" || userType === "Adviser") && (
          <>
            <CustomLink to="/ctc/compra/clientes">
              <div className="navigate-icon-link">
                <RiPriceTag2Line className="nav-icon-size" />
                Clientes
              </div>
            </CustomLink>
            <hr className="divider" />
          </>
        )}
        {(userType === "Admin" || userType === "Adviser") && (
          <>
            <CustomLink to="/ctc/compra/cita">
              <div className="navigate-icon-link">
                <RiGroup2Line className="nav-icon-size" />
                Citas
              </div>
            </CustomLink>
            <hr className="divider" />
          </>
        )}
        {(userType === "Admin" || userType === "Adviser") && (
          <>
            <CustomLink to="/ctc/compra/expediente">
              <div className="navigate-icon-link">
                <MdOutlineSource className="nav-icon-size" />
                Integración de Expediente
              </div>
            </CustomLink>
            <hr className="divider" />
          </>
        )}

        <CustomLink to="/ctc/compra/proceso-de-compra">
          <div className="navigate-icon-link">
            <VscLaw className="nav-icon-size" />
            Inicio Proceso de Compra
          </div>
        </CustomLink>

        {(userType === "Admin" ||
          userType === "Adviser" ||
          userType === "Lawyer") && (
          <>
            <hr className="divider" />
            <CustomLink to="/ctc/compra/firma">
              <div className="navigate-icon-link">
                <MdPendingActions className="nav-icon-size" />
                Firma
              </div>
            </CustomLink>
          </>
        )}
        {(userType === "Admin" || userType === "Adviser") && (
          <>
            <hr className="divider" />
            <CustomLink to="/ctc/compra/reconversion">
              <div className="navigate-icon-link">
                <MdOutlineAssignmentReturn className="nav-icon-size" />
                Reconversion
              </div>
            </CustomLink>
          </>
        )}
      </section>
    </section>
  );
};

export default Sidebar;
