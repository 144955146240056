import { Alert, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

const PerfiladoButton = ({ property, propertyId, refetch }) => {
  const { token } = useContext(TokenContext);
  const [success, setSucces] = useState({ severity: "", text: "" });
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const validateProspect = () => {
      if (
        //valores obligatorios del cliente
        property?.sellerRef?.name !== "" &&
        property?.sellerRef?.fLastName !== "" &&
        property?.sellerRef?.mLastName !== "" &&
        property?.sellerRef?.phoneNumber !== "" &&
        property?.sellerRef?.email !== "" &&
        property?.sellerRef?.credit &&
        property?.sellerRef?.socialSecurity !== "" &&
        //valores obligatorios de direccion en address y property
        property?.country !== "" &&
        property?.state !== "" &&
        property?.city !== "" &&
        property?.settlement !== "" &&
        property?.address?.bedrooms !== "" &&
        property?.address?.washroom !== "" &&
        property?.address?.parkingLot !== "" &&
        property?.address?.mBuilding !== "" &&
        property?.address?.mLand !== "" &&
        property?.adquisitionYear &&
        property?.reasonSale
      ) {
        setIsValid(true);
      } else {
        setIsValid(false);

        return;
      }

      if (typeof property?.sellerRef?.married === "boolean") {
        setIsValid(true);
      } else {
        setIsValid(false);

        return;
      }

      if (property?.type === "Casa") {
        if (property?.address?.levels !== "") {
          setIsValid(true);
        } else {
          setIsValid(false);
          return;
        }
      }

      if (property?.type === "Departamento") {
        console.log(property?.address?.floor);

        if (property?.address?.floor) {
          setIsValid(true);
        } else {
          setIsValid(false);

          return;
        }
      }
    };

    validateProspect();
  }, [property]);

  const sendData = async () => {
    try {
      await updateProperties(token, propertyId, {
        status: "Prospectos"
      });
      setSucces({ severity: "success", text: "Actualizado" });
      setTimeout(() => {
        setSucces({ severity: "", text: "" });
        navigate(`/ctc/compra/propiedad/${propertyId}/cliente`, {
          replace: true
        });
      }, 2000);
    } catch (error) {
      console.log(error);
      setSucces({ severity: "error", text: error });
      setTimeout(() => {
        setSucces({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <>
      {success.severity !== "" && (
        <Alert severity={success.severity}>{success.text}</Alert>
      )}
      <Button
        variant="blue"
        size="large"
        sx={{ width: 250 }}
        onClick={sendData}
        disabled={!isValid}
      >
        Enviar a Clientes
      </Button>
    </>
  );
};

export default PerfiladoButton;
