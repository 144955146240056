import EventIcon from "@mui/icons-material/Event";
import { Box, IconButton, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import { useState } from "react";

import AfterAppointmentOptions from "./afterAppointment/AfterAppointmentOptions";
import AppointmentForm from "./AppointmentForm";

const FirstMeeting = ({ appointment, propertyId, refetch }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Box display={"flex"}>
        <Typography
          variant="h5"
          fontWeight={600}
          textAlign="start"
          className="blue-text"
          mt={1}
        >
          Cliente y cita
        </Typography>
        <IconButton
          onClick={() => setOpenModal(true)}
          sx={{ color: "#0053C5", mt: 0.5 }}
        >
          <EventIcon color="inherit" />
        </IconButton>
        <Typography variant="h6" color="primary" mt={1} ml={2}>
          Fecha de cita:{" "}
          <Typography variant="h6" color="secondary" component={"span"}>
            {appointment?.date
              ? moment
                  .utc(appointment?.date)
                  .format("DD/MM/YYYY [a las] hh:mm a")
              : "Sin cita"}
          </Typography>
        </Typography>

        {openModal && (
          <AppointmentForm
            openModal={openModal}
            setOpenModal={setOpenModal}
            appointment={appointment}
            propertyId={propertyId}
            refetch={refetch}
          />
        )}
      </Box>
      <AfterAppointmentOptions
        propertyId={propertyId}
        appointment={appointment}
        refetch={refetch}
      />
      <Box>
        {appointment?.status === "Éxito" && (
          <Typography
            textAlign={"start"}
            variant="body1"
            fontWeight={600}
            color={green[800]}
          >
            La cita se efectuó correctamente: &quot;{appointment.comments}&quot;
          </Typography>
        )}
        {appointment?.status === "Falló" && (
          <Typography
            textAlign={"start"}
            variant="body1"
            fontWeight={600}
            color="error"
          >
            La cita no se efectuó correctamente: &quot;{appointment.comments}{" "}
            &quot;
          </Typography>
        )}
        {appointment?.status === "Cancelada" && (
          <Typography
            textAlign={"start"}
            variant="body1"
            fontWeight={600}
            color="primary"
          >
            La cita fue cancelada: &quot; {appointment.comments} &quot;
          </Typography>
        )}
      </Box>
    </>
  );
};

export default FirstMeeting;
